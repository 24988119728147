@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
@include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
// @include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
// @include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;


/* *****************************************************************
CUSTOM SETTINGS
***************************************************************** */

// components
@import 'components/fonts-rubik';
// @import 'components/colours';
// @import 'components/layout';
// @import 'components/typography';
// @import 'components/header';
// @import 'components/footer';
@import 'components/topbar';

// @import 'components/foundation-icons';


/* *****************************************************************
COLOURS
***************************************************************** */
$award-lightblue: #00A3E3; // primary
$award-midblue: #0074BD;// backgrounds
$award-darkblue: #302782; // black
$award-gold: #E8AA00; //secondary



/* *****************************************************************
VARIABLES
***************************************************************** */

$award-global-angle-left: -1deg;
$award-global-angle-right: 1deg;
$award-global-shadow: 0 10px 20px 0 rgba($award-darkblue, 0.5);



/* *****************************************************************
TEST
***************************************************************** */
.red {
	background-color: red;
}

.redbox
{
	border: thin red solid;
}


/* *****************************************************************
GENERAL
***************************************************************** */
body {
	display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.wrap{
	width: 96%;
	margin: 0 auto;
}

.main {
	flex: 1;
}
.main {
	@include breakpoint(small) {
		margin-top: 1rem;
	}
	@include breakpoint(medium) {
		margin-top: 8rem;
	}
}

.home .main {
	margin-top: -3rem;
}

// fix for IE: otherwise images will not be scaled proportionally
img {
    width: 100%;
}

.twist-left {
	transform: rotate($award-global-angle-left);
}

.twist-right {
	transform: rotate($award-global-angle-right);
}

.shadow {
	box-shadow: $award-global-shadow;
}

.stack-top {
	position: relative;
	z-index: 10;
}

.stack-back {
	position: relative;
	z-index: -10;
}


/* ************s*****************************************************
NAV
***************************************************************** */
nav {
	background-color: $award-darkblue;
}

img.social {
	height: 40px;
}


/* *****************************************************************
HEADER
***************************************************************** */

header {
	@include breakpoint(medium) {
		max-height: 30rem;
		overflow: hidden;
	}
}

/* *****************************************************************
CONTENT
***************************************************************** */
// .home {
// 	background-image: url(/assets/img/alice_with_reggie_lunge.png);
// 	background-repeat: no-repeat;
// 	background-position: top;
// }
//

.about,
.services,
.testimonials,
.footnote {
	color: $white;
}

.footnote {
	margin-bottom: $global-margin*2;
}
.footnote p {
	font-size: $small-font-size;
	margin-bottom: 0;
	padding-bottom: 0;
}

#social {
	height: 4rem;
	background-color: $award-midblue;
	margin-bottom: 0;
	padding-top: 1rem;
}

#testimonial {
	margin-bottom: 1rem;

}
/* *****************************************************************
FOOTER
***************************************************************** */

footer
{
	background-color: map-get($foundation-palette, secondary);
	min-height: 16rem;
	padding-top: 1rem;
	background-image: url(/assets/img/alice_with_reggie_cutout-25.png);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 20rem;
}


footer .nav li
{
	list-style-type: none;

	a {
		padding-top: .25rem;
		padding-bottom: .25rem;
	}

	a:link, a:visited {
		color: $award-darkblue;
	}

	a:hover {
		text-decoration: underline;
	}
}

/* *****************************************************************
TYPOGRAPHY
***************************************************************** */

h3 {
	font-weight: 500;
}

.slug
{
	color: $white;
	font-size: 1.25rem;
	line-height: 1.5rem;
// 	font-weight: bold;
	margin-top: 4rem;
}

.quote {
	margin-bottom: .5rem;
}

.quote:before
{
	content: "\201C";
}

.quote:after
{
	content: "\201D";
}

.attribution
{
	text-align: right;
	font-weight: lighter;
}

.whitetext {
	color: $white;

	a:link, a:visited {
		color: $white;
		font-weight: bold;
	}

	a:hover {
		text-decoration: underline;
	}
}

/* *****************************************************************
OBJECTS
***************************************************************** */
.snappysnap
{
	border: .5rem solid;
	border-color:  $white;
}

.testimonial {
	background-color: $white;
	border-radius: 0;
	padding: 1.5rem;
	margin: 1rem 0;
}

// Pricing cards

.pricing-table {
  border: solid 2px $black;
  border-radius: 0 0 $global-radius*2 $global-radius*2;
  box-shadow: $award-global-shadow;

  .title {
    background-color: $black;
    color: $white;
  }

  .button {
    text-transform: uppercase;
  }

	.tariff {
		font-weight: 300;
	}

	.tariff h3 {
		padding-top: $global-padding/3;
		margin-top: $global-margin/3;
		margin-bottom: 0;
		border-top: 1px map-get($foundation-palette, primary) solid;
	}

	.price {
		text-align: right;
	}
}


/* *****************************************************************
FORMS
***************************************************************** */

.form
{
	background-color: $award-midblue;
	margin: 1rem auto;
	padding: 1rem 1.5rem;
	color: $white;
	border-radius: $global-radius;

	legend {
		font-weight: bold;
	}

	label {
		color: $white;
		line-height: 1rem;
		margin-top: 1rem;
	}

	input {
		border-radius: 0;
		border: 0px;
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
	}

	textarea {
		border-radius: 0;
		border: 0px;
		margin-top: 0.25rem;
		margin-bottom: .5rem;
	}

	abbr {
		color: $white;
		border-bottom: none;
		text-decoration: none;
	}

	a:link {
		color: $white;
		font-weight: bold;
	}
	a:hover {
		text-decoration: underline;
	}
}


.alert.callout {
	border-radius: 0;
	color: $white;
	font-weight: bold;
}

.is-invalid-input:not(:focus) {
	background-color: $white;
	border: 1px solid;
	border-color: map-get($foundation-palette, alert);
}

.form-error.is-visible {
	background-color: map-get($foundation-palette, alert);
	color: $white;
	padding-left: 0.25rem;
}


/* *****************************************************************
COLOPHON
***************************************************************** */
