@font-face {
  font-family: 'Rubik';
  font-weight: 300;
  font-style: normal;
  src: url('/assets/font/Rubik-300/Rubik-300.eot');
  src: url('/assets/font/Rubik-300/Rubik-300.eot?#iefix') format('embedded-opentype'),
       local('Rubik Light'),
       local('Rubik-300'),
       url('/assets/font/Rubik-300/Rubik-300.woff2') format('woff2'),
       url('/assets/font/Rubik-300/Rubik-300.woff') format('woff'),
       url('/assets/font/Rubik-300/Rubik-300.ttf') format('truetype'),
       url('/assets/font/Rubik-300/Rubik-300.svg#Rubik') format('svg');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  font-style: normal;
  src: url('/assets/font/Rubik-regular/Rubik-regular.eot');
  src: url('/assets/font/Rubik-regular/Rubik-regular.eot?#iefix') format('embedded-opentype'),
       local('Rubik'),
       local('Rubik-regular'),
       url('/assets/font/Rubik-regular/Rubik-regular.woff2') format('woff2'),
       url('/assets/font/Rubik-regular/Rubik-regular.woff') format('woff'),
       url('/assets/font/Rubik-regular/Rubik-regular.ttf') format('truetype'),
       url('/assets/font/Rubik-regular/Rubik-regular.svg#Rubik') format('svg');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  font-style: normal;
  src: url('/assets/font/Rubik-500/Rubik-500.eot');
  src: url('/assets/font/Rubik-500/Rubik-500.eot?#iefix') format('embedded-opentype'),
       local('Rubik Medium'),
       local('Rubik-500'),
       url('/assets/font/Rubik-500/Rubik-500.woff2') format('woff2'),
       url('/assets/font/Rubik-500/Rubik-500.woff') format('woff'),
       url('/assets/font/Rubik-500/Rubik-500.ttf') format('truetype'),
       url('/assets/font/Rubik-500/Rubik-500.svg#Rubik') format('svg');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 700;
  font-style: normal;
  src: url('/assets/font/Rubik-700/Rubik-700.eot');
  src: url('/assets/font/Rubik-700/Rubik-700.eot?#iefix') format('embedded-opentype'),
       local('Rubik Bold'),
       local('Rubik-700'),
       url('/assets/font/Rubik-700/Rubik-700.woff2') format('woff2'),
       url('/assets/font/Rubik-700/Rubik-700.woff') format('woff'),
       url('/assets/font/Rubik-700/Rubik-700.ttf') format('truetype'),
       url('/assets/font/Rubik-700/Rubik-700.svg#Rubik') format('svg');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 900;
  font-style: normal;
  src: url('/assets/font/Rubik-900/Rubik-900.eot');
  src: url('/assets/font/Rubik-900/Rubik-900.eot?#iefix') format('embedded-opentype'),
       local('Rubik Black'),
       local('Rubik-900'),
       url('/assets/font/Rubik-900/Rubik-900.woff2') format('woff2'),
       url('/assets/font/Rubik-900/Rubik-900.woff') format('woff'),
       url('/assets/font/Rubik-900/Rubik-900.ttf') format('truetype'),
       url('/assets/font/Rubik-900/Rubik-900.svg#Rubik') format('svg');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 300;
  font-style: italic;
  src: url('/assets/font/Rubik-300italic/Rubik-300italic.eot');
  src: url('/assets/font/Rubik-300italic/Rubik-300italic.eot?#iefix') format('embedded-opentype'),
       local('Rubik Light Italic'),
       local('Rubik-300italic'),
       url('/assets/font/Rubik-300italic/Rubik-300italic.woff2') format('woff2'),
       url('/assets/font/Rubik-300italic/Rubik-300italic.woff') format('woff'),
       url('/assets/font/Rubik-300italic/Rubik-300italic.ttf') format('truetype'),
       url('/assets/font/Rubik-300italic/Rubik-300italic.svg#Rubik') format('svg');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  font-style: italic;
  src: url('/assets/font/Rubik-italic/Rubik-italic.eot');
  src: url('/assets/font/Rubik-italic/Rubik-italic.eot?#iefix') format('embedded-opentype'),
       local('Rubik Italic'),
       local('Rubik-italic'),
       url('/assets/font/Rubik-italic/Rubik-italic.woff2') format('woff2'),
       url('/assets/font/Rubik-italic/Rubik-italic.woff') format('woff'),
       url('/assets/font/Rubik-italic/Rubik-italic.ttf') format('truetype'),
       url('/assets/font/Rubik-italic/Rubik-italic.svg#Rubik') format('svg');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  font-style: italic;
  src: url('/assets/font/Rubik-500italic/Rubik-500italic.eot');
  src: url('/assets/font/Rubik-500italic/Rubik-500italic.eot?#iefix') format('embedded-opentype'),
       local('Rubik Medium Italic'),
       local('Rubik-500italic'),
       url('/assets/font/Rubik-500italic/Rubik-500italic.woff2') format('woff2'),
       url('/assets/font/Rubik-500italic/Rubik-500italic.woff') format('woff'),
       url('/assets/font/Rubik-500italic/Rubik-500italic.ttf') format('truetype'),
       url('/assets/font/Rubik-500italic/Rubik-500italic.svg#Rubik') format('svg');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 700;
  font-style: italic;
  src: url('/assets/font/Rubik-700italic/Rubik-700italic.eot');
  src: url('/assets/font/Rubik-700italic/Rubik-700italic.eot?#iefix') format('embedded-opentype'),
       local('Rubik Bold Italic'),
       local('Rubik-700italic'),
       url('/assets/font/Rubik-700italic/Rubik-700italic.woff2') format('woff2'),
       url('/assets/font/Rubik-700italic/Rubik-700italic.woff') format('woff'),
       url('/assets/font/Rubik-700italic/Rubik-700italic.ttf') format('truetype'),
       url('/assets/font/Rubik-700italic/Rubik-700italic.svg#Rubik') format('svg');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 900;
  font-style: italic;
  src: url('/assets/font/Rubik-900italic/Rubik-900italic.eot');
  src: url('/assets/font/Rubik-900italic/Rubik-900italic.eot?#iefix') format('embedded-opentype'),
       local('Rubik Black Italic'),
       local('Rubik-900italic'),
       url('/assets/font/Rubik-900italic/Rubik-900italic.woff2') format('woff2'),
       url('/assets/font/Rubik-900italic/Rubik-900italic.woff') format('woff'),
       url('/assets/font/Rubik-900italic/Rubik-900italic.ttf') format('truetype'),
       url('/assets/font/Rubik-900italic/Rubik-900italic.svg#Rubik') format('svg');
}

